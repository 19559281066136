@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/Gotham/Gotham-Bold.otf') format('truetype');
    font-style: bold;
    font-weight: 700;
    font-display: swap;
}

